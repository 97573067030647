import { PageProps } from "gatsby";
import React from "react";

import { Scales } from "../components/pages/Scales";

import { snProps } from "../js/utils";

const ScalesPage = (props: PageProps) => (
  <Scales {...{ ...snProps, ...props }} />
);

export default ScalesPage;
